import {request} from "../request";
//请求个人基本信息
export function personalMsg(userDate){
  return request({
    url:'user/selectUserInfo',
    method:'get',
    params:userDate
  })
}
//保存修改
export function updateMsg(userDate){
  return request({
    url:'user/updateUser',
    method:'post',
    params:userDate
  })
}
//添加用户
export function addUserMsg(userDate){
  return request({
    url:'user/addUser',
    method:'post',
    params:userDate
  })
}
//查询地址
export function searchAddress(data){
  return request({
    url:'address/selectAllAddress',
    method:'get',
    params:data
  })
}
//修改密码
export function updatePassword(data){
  return request({
    url:'/user/updatePassword',
    method:'post',
    params:data
  })
}
