<template>
  <div class="container">
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        :color="snackbarColor"
        absolute
        top
        v-model="snackbarShow"
    >{{snackbarText}}
    </v-snackbar>
    <h4 class="title">基本信息</h4>
    <v-divider></v-divider>
    <div class="msg_container">
      <!-- 头像-->
      <div class="icon mb-7">
        <div class="mr-4">头像</div>
        <v-avatar
            size="66"
            color="#42A5F5"
        >
          <h5 class="white--text">{{userMsg.roleName}}</h5>
        </v-avatar>
      </div>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--账号-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">账号</div>
            <div class="input_width">
              <v-text-field
                  readonly
                  label="请输入"
                  solo
                  dense
                  :value="userMsg.username"
                  v-model="userMsg.username"
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <!--生日-->
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">生日</div>
            <div class="input_width">
              <v-text-field
                  type="date"
                  label="请输入"
                  solo
                  dense
                  :value="userMsg.birthday"
                  v-model="userMsg.birthday"
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--性别-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">性别</div>
            <div class="input_width">
              <v-select
                  :items="['男','女']"
                  :value="userMsg.sex"
                  solo
                  label="请选择..."
                  v-model="userMsg.sex"
              ></v-select>
            </div>
          </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--邮箱-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">邮箱</div>
            <div class="input_width">
              <v-text-field
                  label="请输入"
                  solo
                  dense
                  :value="userMsg.email"
                  v-model="userMsg.email"
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--地址-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">地址</div>
            <div class="input_width">
              <v-select
                  :items="addressList"
                  :value="userMsg.addressName"
                  solo
              ></v-select>
            </div>
          </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--手机-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">手机</div>
            <div class="input_width">
              <v-text-field
                  label="请输入"
                  solo
                  dense
                  :value="userMsg.telephone"
                  v-model="userMsg.telephone"
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--工作地点-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2 p_two">工作地点</div>
            <div class="input_width">
              <v-text-field
                  readonly
                  label="工作地点"
                  solo
                  dense
                  :value="userMsg.workPlaceName"
                  v-model="userMsg.workPlaceName"
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--上级-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">上级</div>
            <div class="input_width">
              <v-text-field
                  readonly
                  label="上级管理员"
                  solo
                  dense
                  :value="userMsg.managerName"
                  v-model="userMsg.managerName"
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--职位-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">职位</div>
            <div class="input_width">
              <v-text-field
                  readonly
                  label="职位权限"
                  solo
                  dense
                  :value="userMsg.roleName"
                  v-model="userMsg.roleName"
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--管理辖区-->
          <div class="d-flex flex-row">
            <div class="mr-4 mt-2">辖区</div>
            <div class="input_width">
              <v-text-field
                  readonly
                  label="管理辖区"
                  solo
                  dense
                  :value="userMsg.jurisdiction"
                  v-model="userMsg.jurisdiction"
              ></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row class="bt_row mt-6">
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--密码修改-->
          <v-btn
              color="#42A5F5"
              rounded
              width="100px"
              height="40px"
              dark
              style="font-weight: bold;font-size: 16px"
              @click="editPassword"
          >密码修改</v-btn>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <!--    保存按钮-->
          <v-btn
              color="#42A5F5"
              rounded
              width="100px"
              height="40px"
              dark
              style="font-weight: bold;font-size: 16px"
              @click="saveBt"
          >保存修改</v-btn>
        </v-col>
      </v-row>
    </div>
    <!--修改密码弹窗-->
    <v-dialog
        v-model="dialog"
        max-width="420px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">密码修改</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="12"
            >
              <v-text-field
                  label="原密码"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  v-model="oldPassword"
                  :rules="[rules.min]"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="12"
            >
              <v-text-field
                  label="新密码"
                  maxlength="25"
                  counter="25"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  v-model="newPassword1"
                  :rules="[rules.min]"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="12"
            >
              <v-text-field
                  label="确认新密码"
                  maxlength="25"
                  counter="25"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  v-model="newPassword2"
                  :rules="[rules.min,diff]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="updateNew"
          >
            提交
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {personalMsg,searchAddress,updateMsg,updatePassword} from "../../network/Details/personal";

export default {
  name: "Personal",
  data(){
    return{
      username:'',
      updateList:{
        sex:'',
        telephone:'',
        addressName:'',
        birthday:'',
        email:''
      },
      //地址复选框
      addressList:[],
      //表单验证
      rules: {
        min: v => v.length >= 6 || '密码最短长度为6',
      },
      //密码显示
      show:false,
      oldPassword:'',
      newPassword1:'',
      newPassword2:'',
      dialog:false,
      userMsg:{},
      //提示
      snackbarShow:false,
      snackbarText:'',
      snackbarColor:'#42A5F5',
    }
  },
  created() {
    searchAddress().then(res => {
      this.addressList = res.data;
    })
    this.username = window.localStorage.getItem("username");
    personalMsg({
      username:this.username
    }).then(res => {
      if(res.code === 200){
        this.userMsg = res.data;
        this.updateList.sex = this.userMsg.sex;
        this.updateList.telephone = this.userMsg.telephone;
        this.updateList.addressName = this.userMsg.addressName;
        this.updateList.birthday = this.userMsg.birthday;
        this.updateList.email = this.userMsg.email;
      }
      this.snackbarText = res.message;
      this.snackbarShow = true;
    })
  },
  methods:{
    //保存
    saveBt(){
      let data = {};
      data.username = this.username;
      let flag = 0;
      if(this.updateList.sex != this.userMsg.sex){
        data.sex = this.userMsg.sex;
        flag++;
      }
      if(this.updateList.email != this.userMsg.email){
        data.email = this.userMsg.email;
        flag++;
      }
      if(this.updateList.birthday != this.userMsg.birthday){
        data.birthday = this.userMsg.birthday;
        flag++;
      }
      if(this.updateList.addressName != this.userMsg.addressName){
        data.addressName = this.userMsg.addressName;
        flag++;
      }
      if(this.updateList.telephone != this.userMsg.telephone){
        data.telephone = this.userMsg.telephone;
        flag++;
      }
      if(flag > 0){
        console.log(data)
      updateMsg(data).then(res => {
        this.snackbarText = res.message;
        this.snackbarShow = true;
      })
    }
  },
  //密码修改
  editPassword(){
    this.dialog = true;
  },
  //弹窗保存
  updateNew(){
      if(this.oldPassword === this.newPassword1){
        this.snackbarText = "新旧密码一致，修改失败！";
        this.snackbarShow = true;
      }else {
        if(this.oldPassword.length >= 6 && this.newPassword1.length >= 6 && this.newPassword1 === this.newPassword2){
          updatePassword({
            username:this.username,
            oldPassword:this.oldPassword,
            newPassword:this.newPassword1
          }).then(res => {
            console.log(res);
            this.snackbarText = res.message;
            this.snackbarShow = true;
          })
        }
      }
      this.dialog = false;
  },
  //验证两次密码是否一致
  diff(value){
    if(value != this.newPassword1){
      return "两次输入的密码不一致"
    }else {
      return true;
    }
  }
}
}
</script>
<style lang="less" scoped>
.container {
  background-color: white;
  width: 80%;
  height: 700px;
  margin-left: 10%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  .msg_container{
    margin-left: 3%;
    margin-top: 20px;
    .icon{
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }
}
.input_width{
  width: 300px;
}
.bt_row{
  margin-left: 20%;
}
.p_two{
  margin-top: 0px !important;
  width: 34px;
}
</style>
